import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { updatePreset } from "@primevue/themes";
import { palette } from "@primevue/themes";

export async function loadTheme() {
  const application = localStorage.getItem("activeApplication");
  let appId = application ? JSON.parse(application)?.id : null;

  const path = window.location.pathname;
  if (!appId && path.includes("/app/")) {
    appId = path.split("/app/")[1];
    if (!/^[0-9]+$/.test(appId)) {
      appId = null;
    }
  }

  const appConfigRef = appId
    ? ref(storage, `configuration/${appId}/configuration.json`)
    : ref(storage, `configuration/default/configuration.json`);
  try {
    await processConfig(appConfigRef);
  } catch (e) {
    await processConfig(appConfigRef);
  }
}

async function processConfig(r) {
  let url = await getDownloadURL(r);
  let result = await fetch(url);
  let data = await result.json();

  if (data.theming) {
    if (data.theming.primary) {
      updatePreset({
        semantic: {
          primary: palette(data.theming.primary),
        },
      });
    }
    if (data.theming.secondary) {
      updatePreset({
        semantic: {
          secondary: palette(data.theming.secondary),
        },
      });
    }
    if (data.theming.logo) {
      updatePreset({
        semantic: {
          logoUrl: `url(${data.theming.logo})`,
        },
      });
    }
  }
}
