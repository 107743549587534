import { createI18n } from "vue-i18n";
import de from "./locales/de.json";
import de_DE_simple from "./locales/de-DE-simple.json";
import en from "./locales/en.json";
import ro from "./locales/ro.json";
import { localeOptions } from "./constants/config";

function isLocale(path) {
  if (localeOptions.some((o) => o.id === path)) {
    return path;
  } else {
    return false;
  }
}

export default createI18n({
  legacy: false,
  globalInjection: true, //TODO remove after update to 9.2 as it is the default
  locale:
    // We need to test, if the first path param is actually a language or is just a dynamic link
    isLocale(window.location.pathname.split("/")[1]) ||
    localStorage.getItem("i18n-locale") ||
    navigator.language.split("-")[0],
  fallbackLocale: "de",
  messages: { en, de, "de-DE-simple": de_DE_simple, ro },
});
