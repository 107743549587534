import { createRouter, createWebHistory } from "vue-router";
import { loadTheme } from "./modules/theme";
import i18n from "./i18n";

const routes = [
  {
    path: "/",
    // In case the user navigates to the root path, redirect to the language root path
    redirect: () => {
      const language = i18n.global.locale.value;
      return `/${language}`;
    },
  },
  {
    path: "/:param",
    // Check if the first param is a language or a dynamic link
    beforeEnter: (to) => {
      const param = to.params.param;
      // If the first param (e.g. /de) is a language, set it and continue
      if (i18n.global.availableLocales.includes(param)) {
        i18n.global.locale.value = param;
        return true;
        // If the first param is not a language, but is present, assume it is a dynamic link (/obwahl-halle)
      } else if (param.length > 0) {
        console.log("is dynamic link");
        return { path: `/dynamic/${param}` };
        // If no param is present, just reroute to the current detected langauge
      } else {
        return {
          path: `${i18n.global.locale.value}${to.fullPath}`,
        };
      }
    },
    children: [
      {
        path: "",
        component: () => import("./views/start.vue"),
      },
      {
        path: "app/:id",
        component: () => import("./views/app.vue"),
      },
      {
        path: "theses",
        component: () => import("./views/theses.vue"),
      },
      {
        path: "matches",
        component: () => import("./views/matches.vue"),
      },
      {
        path: "faq",
        component: () => import("./views/faq.vue"),
      },
      {
        path: "match/candidates/:id",
        component: () => import("./views/match.vue"),
        meta: { comparison: "candidates" },
      },
      {
        path: "match/parties/:id",
        component: () => import("./views/match.vue"),
        meta: { comparison: "parties" },
      },
    ],
  },
  {
    path: "/dynamic/:slug",
    component: () => import("./views/dynamic.vue"),
  },
];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  mode: "history",
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  if (to.path && to.path !== "/") {
    await loadTheme();
  }
});

export default router;
